import { NarrowFulfilmentMethodInputType as FulfillmentType } from '@src/graphql-types'

interface Outlet {
  isOpen: boolean
  isOnline: boolean
  allowPreorders: boolean
  specialOfferFlags?: {
    deliveryDeal?: string | null
    collectionDeal?: string | null
    tableDeal?: string | null
  }
  displayName: string
  id: string
}

const isFulfilmentMethodAvailable = (
  outlet: Outlet,
  method: FulfillmentType
): boolean => {
  switch (method) {
    case FulfillmentType.DELIVERY:
      return outlet.isOpen && Boolean(outlet.specialOfferFlags?.deliveryDeal)
    case FulfillmentType.COLLECTION:
      return (
        outlet.isOpen &&
        outlet.isOnline &&
        Boolean(outlet.specialOfferFlags?.collectionDeal)
      )
    case FulfillmentType.TABLE:
      return outlet.isOpen && Boolean(outlet.specialOfferFlags?.tableDeal)
    default:
      return false
  }
}

const getFulfilmentDeal = (
  outlet: Outlet,
  method: FulfillmentType
): string | null | undefined => {
  switch (method) {
    case FulfillmentType.DELIVERY:
      return outlet.specialOfferFlags?.deliveryDeal
    case FulfillmentType.COLLECTION:
      return outlet.specialOfferFlags?.collectionDeal
    case FulfillmentType.TABLE:
      return outlet.specialOfferFlags?.tableDeal
  }
}

export const findValidSpecialOfferFlag = (
  outlet: Outlet,
  nonEmptyFulfilmentMethods: FulfillmentType[]
): string | null | undefined => {
  if (!outlet?.specialOfferFlags) return null
  const hasDeliveryAndCollection =
    nonEmptyFulfilmentMethods.includes(FulfillmentType.DELIVERY) &&
    nonEmptyFulfilmentMethods.includes(FulfillmentType.COLLECTION)

  if (
    // prioritize delivery deal if both available regardless of order nonEmptyFulfilmentMethods
    hasDeliveryAndCollection &&
    isFulfilmentMethodAvailable(outlet, FulfillmentType.DELIVERY) &&
    isFulfilmentMethodAvailable(outlet, FulfillmentType.COLLECTION)
  ) {
    return outlet.specialOfferFlags.deliveryDeal
  }
  for (const method of nonEmptyFulfilmentMethods) {
    if (isFulfilmentMethodAvailable(outlet, method)) {
      return getFulfilmentDeal(outlet, method)
    }
  }
  // handles deals for pre-order available cases
  if (
    nonEmptyFulfilmentMethods.length === 1 &&
    nonEmptyFulfilmentMethods[0] === FulfillmentType.DELIVERY &&
    outlet.allowPreorders
  ) {
    return outlet.specialOfferFlags.deliveryDeal
  }
  if (
    nonEmptyFulfilmentMethods.length === 1 &&
    nonEmptyFulfilmentMethods[0] === FulfillmentType.COLLECTION &&
    outlet.allowPreorders
  ) {
    return outlet.specialOfferFlags.collectionDeal
  }
  return null
}
