import styled, { css } from 'styled-components'

import { LinkToOutlet } from '@src/components/LinkToOutlet'
import { breakpoints } from '@src/constants/breakpoints'

import { SingleOutletDirection } from './types'

import { Button } from '../Button'
import { DotSpan } from '../DotSpan/DotSpan'
import { CartSmallSVG } from '../SVGS/CartSmallSVG'
import { CouponSVG } from '../SVGS/CouponSVG'
import { StarSVG } from '../SVGS/StarSVG'
import { TimeSmallSVG } from '../SVGS/TimeSmallSVG'

export const RestaurantContainer = styled.div<{
  direction: SingleOutletDirection
  hasOffer: boolean
  isSlide: boolean
}>`
  all: unset;
  display: flex;
  flex-direction: row;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  margin-top: ${({ isSlide }) => (isSlide ? '0px' : '12px')};

  @media (min-width: ${breakpoints.tablet}px) {
    height: 100%;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: ${({ isSlide }) => (isSlide ? '0px' : '12px')};
  }

  ${({ direction }) =>
    direction === SingleOutletDirection.ROW &&
    css`
      @media (max-width: ${breakpoints.tablet}px) {
        ${CoverImage} {
          height: 100%;
          max-height: 249px;
          border-radius: 8px 8px 8px 8px;
          flex-shrink: 0;

          @media (min-width: ${breakpoints.phablet}px) {
            width: 32%;
            border-radius: 4px 0 0 4px;
          }
        }

        ${OutletDetailsContainer} {
          width: 59%;
          height: 100%;

          @media (min-width: ${breakpoints.phablet}px) {
            width: 68%;
          }
        }

        ${OutletOpeningContainer} {
          @media (min-width: ${breakpoints.phablet}px) {
            flex-direction: row;
          }
        }

        ${OpenStatusContainer} {
          @media (max-width: ${breakpoints.phablet}px) {
          }
        }
      }
    `}
`

export const CuisineDot = styled(DotSpan)`
  color: ${({ theme }) => theme.colors.mainText};
  font-weight: 400;
`

export const OutletDetailsContainer = styled.div<{
  isSlide: boolean
}>`
  padding-left: 8px;
  margin: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.tablet}px) {
    padding-left: 16px;
    width: auto;
  }
`

export const OutletName = styled.h5`
  margin: 0px;
  height: 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.mainText};
  @media (min-width: ${breakpoints.tablet}px) {
    font-weight: 700;
    font-size: 16px;
    height: auto;
    line-height: 20px;
  }
`

export const OutletNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CuisinesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  align-content: flex-start;
  flex: 1;
  color: ${({ theme }) => theme.colors.mainText};

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 2px 0 0px 0;
    align-content: center;
    height: 16px;
    flex-wrap: nowrap;
  }
`

export const CuisineTag = styled.div`
  display: inline-flex;
  flex-direction: row;

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.mainText};
    font-weight: 400;
    margin-right: 4px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 2px;

    @media (min-width: ${breakpoints.tablet}px) {
      margin-right: 5px;
      height: 14px;
      font-weight: 400;
    }
  }
`

export const Cuisine = styled.p`
  margin: 0 6px 0 0;
  font-size: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  opacity: 0.6;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: 5px;
  }
`

export const OutletOpeningContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
`

export const OpenStatusContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
`
export const DotContainer = styled.span.attrs(() => ({ role: 'separator' }))`
  display: flex;
  flex-direction: row;
  margin: 0 6px 0 0;
`

export const LongSquareButton = styled(Button)<{ status: string }>`
  height: 32px;
  width: auto;
  border-radius: 24px;
  font-size: 11px;
  font-weight: 700;
  padding: 0 14px;
  min-height: 32px;
  border: none;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 32px;
  }
  text-transform: uppercase;

  ${({ theme, status }) => {
    if (status === 'open' || status === 'order') {
      return css`
        color: ${theme.colors.openStatus};
        background-color: ${`${theme.colors.openStatus}20`};
      `
    }
    if (status === 'pre_order') {
      return css`
        color: ${theme.colors.preorderStatus};
        background-color: ${`${theme.colors.preorderStatus}20`};
      `
    }
    return css`
      color: #939393;
      background-color: #93939320;
    `
  }}
`

export const LinkToOutletContainer = styled(LinkToOutlet)<{ status: string }>`
  box-sizing: border-box;
  padding: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(150, 150, 150, 0.2);

  @media (min-width: ${breakpoints.tablet}px) {
    border-radius: 8px;
    background-color: white;
    border-bottom-width: 2px;
    margin: 0 16px 16px 0;
    border-bottom: none;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      h5 {
        color: ${({ theme }) => theme.colors.brand};
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    transition: 0.3s ease;
    transition-property: border-color;

    &:hover {
      h5 {
        color: ${({ theme }) => theme.colors.brand};
      }
      ${LongSquareButton} {
        ${({ theme, status }) => {
          if (status === 'open' || status === 'order') {
            return css`
              color: #fff;
              background-color: ${theme.colors.openStatus};
            `
          }
          if (status === 'pre_order') {
            return css`
              color: #fff;
              background-color: ${theme.colors.preorderStatus};
            `
          }
          return css`
            color: #fff;
            background-color: #939393;
          `
        }}
      }
    }
  }
`

const CoverImage = styled.div<{
  imageUrl: string
}>`
  height: 176px;
  background-color: #c4c4c4;
  border-radius: 8px 8px 0 0;
  position: relative;
  flex-shrink: 0;
  background-size: cover;
  background-position: 50%;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `};

  @media (min-width: ${breakpoints.tablet}px) {
    height: 132px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    height: 176px;
    border-radius: 4px 4px 0 0;
  }
  @media (min-width: ${breakpoints.superWideDesktop}px) {
    height: 176px;
    width: 100%;
  }
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
  isSlide: boolean
}>`
  height: 78px;
  width: 78px;
  top: 16px;
  left: 16px;
  border-radius: 8px;
  background-size: cover;
  background-position: 50%;
  margin-right: 8px;
  z-index: 1;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `};

  @media (min-width: ${breakpoints.tablet}px) {
    float: left;
    height: 72px;
    width: 72px;
    margin-right: 0;
  }
`

export const StyledDiscountSVG = styled(CouponSVG)`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`

export const OfferContainer = styled.div<{
  visible: boolean
}>`
  opacity: ${props => (props.visible ? '1' : '0')};
  align-items: center;
  color: ${({ theme }) => theme.colors.flag};
  width: fit-content;
  margin-bottom: 4px;
  display: ${props => (props.visible ? 'flex' : 'none')};
`

export const OfferName = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  margin: 0;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }
`

export const OrderInfoContainer = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.mainText};
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.mainText};
  margin: 4px 0;
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 0px;
    display: block;
  }
`

export const DiscountTextContainer = styled.div`
  margin: 6px 0 0 0;

  color: ${({ theme }) => theme.colors.discount};
`
export const DiscountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  letter-spacing: 0.4px;
`

export const StyledStarSVG = styled(StarSVG)`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  fill: ${({ theme }) => theme.colors.flag};
`

export const StyledCartSVG = styled(CartSmallSVG)`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`

export const StyledClockSVG = styled(TimeSmallSVG)`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`
